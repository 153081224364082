import { USER_SETTING } from '../redux/constants';
import { ITimezone } from '../types';

export const DEFAULT_VALUES = {
  PAGE_INDEX: 1,
  PAGE_SIZE: 10,
  OFFSET: 0,
};

export const ACTION = {
  VIEW: 'VIEW',
  EDIT: 'EDIT',
};

export const MODULE_PERMISSION = {
  USER: {
    VIEW: 'UserView',
    ADD: 'UserAdd',
    EDIT: 'UserEdit',
    DELETE: 'UserDelete',
  },
  USER_ROLE: {
    VIEW: 'UserRoleView',
    ADD: 'UserRoleAdd',
    EDIT: 'UserRoleEdit',
    DELETE: 'UserRoleDelete',
  },
  METER: {
    VIEW: 'MeterView',
    ADD: 'MeterAdd',
    EDIT: 'MeterEdit',
    DELETE: 'MeterDelete',
    VIEW_AUDIT: 'AuditLogView',
    DATA_INGESTION: 'DataIngestionView',
    RESYNC_ADVANCE_METER_DETAILS: 'ResyncAdvanceMeterDetails',
    RESYNC_ADVANCE_METER_DATA: 'ResyncAdvanceMeterData',
    NMI_GENERATOR: 'NMIGeneratorScreenView',
    SERIAL_GENERATOR: 'SerialNumberGeneratorScreenView',
    PROPERTY_GENERATOR: 'PropertyNumberGeneratorScreenView'
  },
  METERREADING: {
    ADD: 'MeterReadingAdd',
    VIEW: 'MeterReadingView',
    EDIT: 'MeterReadingEdit',
    DELETE: 'MeterReadingDelete',
  },
  METER_GROUP: {
    VIEW: 'MeterGroupView',
    ADD: 'MeterGroupAdd',
    EDIT: 'MeterGroupEdit',
    DELETE: 'MeterGroupDelete',
  },
  DEVICE_STATUS: {
    VIEW: 'DeviceStatusView',
  },
  SITE_MANAGEMENT: {
    VIEW: 'SiteManagementView',
    Add: 'SiteManagementAdd',
    EDIT: 'SiteManagementEdit',
    DELETE: 'SiteManagementDelete',
  },
  CONTACT: {
    VIEW: 'ContactManagementView',
    ADD: 'ContactManagementAdd',
    EDIT: 'ContactManagementEdit',
    DELETE: 'ContactManagementDelete',
  },
  METER_STATUS: {
    VIEW: 'MeterStatusView',
    ADD: 'MeterStatusAdd',
    EDIT: 'MeterStatusEdit',
    DELETE: 'MeterStatusDelete',
  },
  CONFIGURATION: {
    CONFIGURE_METER_CHANNEL: 'ConfigureMeterChannelEdit',
    VIEW_CUSTOMER_NAME: 'CustomerNameView',
    TOU_VIEW: 'TimeOfUseView',
    TOU_ADD: 'TimeOfUseAdd',
    TOU_EDIT: 'TimeOfUseEdit',
    TOU_DELETE: 'TimeOfUseDelete',
    IOT_METER_VIEW: 'IOTMeterCalculationView',
    IOT_METER_ADD: 'IOTMeterCalculationAdd',
    IOT_METER_DELETE: 'IOTMeterCalculationDelete',
    IOT_METER_EDIT: 'IOTMeterCalculationEdit',
    VIEW_LOGS: 'ActivityLogCleanupView',
    NMI_SUFFIX_VIEW: 'NMISuffixView',
    NMI_SUFFIX_ADD: 'NMISuffixAdd',
    UOM_CONFIG_VIEW: 'MeterTypeUOMConfigView',
    UOM_CONFIG_ADD: 'MeterTypeUOMConfigAdd',
    DEVICE_STATUS_VIEW: 'DeviceStatusConfigView',
    DEVICE_STATUS_ADD: 'DeviceStatusConfigAdd',
    EMAIL_CONFIGURATION: 'EmailConfigurationView',
    METER_CHANNEL_VIEW: 'MeterChannelView',
    METER_CHANNEL_ADD: 'MeterChannelAdd',
    METER_CHANNEL_DELETE: 'MeterChannelDelete',
    LOGO_UPLOAD_VIEW: 'LogoUploadView',
    METEREVENTS_VIEW: 'MeterEventsView',
    CONTACT_SUPPORT_VIEW: 'ContactSupportView',
    PUBLIC_HOLIDAY_VIEW: 'PublicHolidayView',
    PUBLIC_HOLIDAY_ADD: 'PublicHolidayAdd',
    PUBLIC_HOLIDAY_DELETE: 'PublicHolidayDelete'
  },
  NEM12: {
    VIEW: 'NEM12View',
  },
  SCHEDULE_LINK: {
    VIEW: 'MeterScheduleLinkView',
    ADD: 'MeterScheduleLinkAdd',
    EDIT: 'MeterScheduleLinkEdit',
    DELETE: 'MeterScheduleLinkDelete',
  },
  UNPROVISIONED_METER: {
    VIEW: 'UnprovisionedMeterView',
  },
  VIRTUAL_METER: {
    VIEW: 'VirtualMeterView',
    ADD: 'VirtualMeterAdd',
    EDIT: 'VirtualMeterEdit',
    DELETE: 'VirtualMeterDelete',
  },
  SCHEDULE_REPORT: {
    VIEW: 'eNEM12ReportView',
    ADD: 'eNEM12ReportAdd',
    EDIT: 'eNEM12ReportEdit',
    DELETE: 'eNEM12ReportDelete',
  },
  LOGS: {
    NEM12SCHEDULER: 'Nem12SchedulerView',
    IOTHUBSCHEDULER: 'IotHubSchedulerView',
    DATASYNC: 'IOTHubDataSyncView',
    SERVICEBUS: 'ServiceBusQueuesView',
    ENEM12REPORTEXECUTION: 'Enem12ReportExecutionView',
    SCHEDULEIMPORT: 'MeterScheduleImportView',
    UNPROCESSEDFILES: 'UnprocessedFilesView',
    NEM12REPORTEXECUTION: 'NEM12ReportExecution',
    USERAUDIT:'UserAuditView'
  },
  VALIDATION: {
    VIEW: "DataValidationView",
    ADD: "DataValidationAdd",
    EDIT: "DataValidationEdit",
    DELETE: "DataValidationDelete"
  },
  METER_SCHEDULE: {
    VIEW: "MeterScheduleLogicAppView",
    ADD: "MeterScheduleLogicAppSave",
    MeterScheduleAllColumnsView: "MeterScheduleAllColumnsView",
  },
  VIEW_CONFIGURATION: {
    VIEW_METER_CUSTOM_PROPERTIES: "MeterCustomPropertiesView",
    VIEW_METERGROUP_USERS: "MeterGroupUsersView",
  },
  NEM12_QUALITY_FLAG_REPORT: {
    VIEW: 'Nem12QualityFlagReportView',
    ADD: 'Nem12QualityFlagReportAdd',
    EDIT: 'Nem12QualityFlagReportEdit',
    DELETE: 'Nem12QualityFlagReportDelete',
  },
  METER_EVENT_REPORT: {
    VIEW: 'MeterEventReportView',
    ADD: 'MeterEventReportAdd',
    EDIT: 'MeterEventReportEdit',
    DELETE: 'MeterEventReportDelete',
  },
  NEM12_REPORT: {
    VIEW: 'NEM12ReportView',
    ADD: 'NEM12ReportAdd',
    EDIT: 'NEM12ReportEdit',
    DELETE: 'NEM12ReportDelete',
  },
  MDN_REPORT: {
    VIEW: 'MDNReportView',
    ADD: 'MDNReportAdd',
    EDIT: 'MDNReportEdit',
    DELETE: 'MDNReportDelete',
  },
  REPORT_VIEW: {
    NEM12_REPORT_VIEW: 'NEM12ReportOutputView',
    NEM12_QUALITY_FLAG_REPORT_VIEW: 'Nem12QualityFlagReportOutputView',
    ENEM12_REPORT_VIEW: 'eNEM12ReportOutputView',
    METER_EVENT_REPORT_VIEW: 'MeterEventReportOutputView',
    MDN_REPORT_VIEW: 'MDNReportOutputView'
  },
  METER_DATA_CORRECTION: {
    METER_DATA_CORRECTION_VIEW: 'MeterDataCorrectionView'
  },
  VIEW_DATA_VALIDATION_REPORT: {
    DATA_VALIDATION_REPORT_VIEW: 'DataValidationReportOutputView'
  },
  DATA_SYNC: {
    CET_DATA_SYNC: 'CETDataSync',
    ADVANCED_ECO_DATA_SYNC: 'AdvancedEcoDataSync',
  },
  VALIDATION_SUMMARY:{
    ADVANCED_ECO_VALIDATION_SUMMARY_VIEW : 'AdvancedEcoValidationSummaryView'
  }
};

export const ROUTER = {
  DASHBOARD: '/dashboard',
  METER_GROUP: '/master/meter-groups',
  DEVICE_STATUS: '/master/meter/device-status',
  SITE_MANAGEMENT: '/master/site-management',
  USER: '/master/users',
  USER_AUDIT: '/master/user-audit',
  METER: '/master/meter/meters',
  CHANGE_METER: '/master/meter/change-meter',
  ROLE: '/master/roles',
  CONTACT: '/master/contacts',
  METER_DATA_AUDIT: '/master/meter/data-audit',
  METER_STATUS: '/master/meter/status',
  UNPROVISIONED_METER: '/master/meter/unprovisioned',
  UNPROVISIONED_IOT_DEVICES: '/master/meter/unprovisioned-iot-devices',
  DASHBOARD_CONFIGURATION: '/master/configuration',
  NEM12: '/nem12',
  PROFILE: '/profile',
  API_MENU: '/api-menu',
  IMPORT_METERS: '/master/meter/import',
  VIRTUAL_METERS: '/master/meter/virtual-meters',
  DATA_SYNC: '/master/meter/data-sync',
  SCHEDULE_LOGS: '/logs/view-logs',
  CLEAN_UP_LOGS: '/logs/clean-up',
  REPORTS: '/report/reports',
  VIEWREPORTS: '/report/view-reports',
  NEM12_QUALITY_FLAG: '/report/nem12-quality-flag-reports',
  METER_EVENT_REPORT: '/report/meter-event-reports',
  LOGS: '/report/logs',
  VALIDATION_DATA: '/validation/validation-data',
  DATA_VALIDATION_REPORT: '/validation/data-validation-report',
  METER_SCHEDULE: '/meter-schedule/view-schedule',
  NEM12_REPORT: '/report/nem12-report',
  MDN_REPORT: '/report/mdn-report',
  NOTIFICATION_ACKNOWLEDGE: '/notification-acknowledge',
  METER_COMPARISON: '/meter-data-comparison',
  DATA_PREVIEW: '/data-preview',
  COMPANY_CONFIGURATION: '/company-configuration',
  CONTACT_SUPPORT: '/contact-support-request',
  CONTACT_SUPPORT_LIST: '/contact-support',
  IOT_PAYLOAD_PROPERTY: '/event-alarm/iot-payload',
  METER_ALARM_EVENT: '/event-alarm/meter-alarm-event',
  METERDATACORRECTION: '/master/meter-data-correction',
  NMI_GENERATOR: '/master/meter/nmi-generator',
  CET_DATA_SYNC: '/master/meter/cet-data-sync',
  SERIAL_GENERATOR: '/master/meter/serial-generator',
  PROPERTY_GENERATOR: '/master/meter/property-generator',
  ADVANCED_ECO_DATA_SYNC: '/master/meter/advanced-eco-data-sync',
  ADVANCED_ECO_VALIDATION_SUMMARY: '/master/meter/advanced-eco-validation-summary',
  DATA_VALIDATION_REPORT_RULS: '/validation/data-validation-report-ruls-summary',
};

export const API_RESPONSE_CODE = {
  SUCCESS: 200,
};

export const EXPORT_DATA_ERROR = {
  CHANNEL_NAME: 'Channel name is required',
  TRANSACTION_CODE: 'Transaction Code is required',
  EQUIVALENT_300: 'Equivalent 300 channel is required'
}
export const STATUS_MAPPING_ERROR = {
  METRIX_STATUS: 'Metrix status is required',
  QUALITY_FLAG: 'Quality flag is required',
  TEXT_DESCRIPTION: 'Text description is required'
}
export const DELETE_WARNING = 'Are you sure you want to delete?';

export const API_ERROR_MESSAGE = 'Internal server error';

export const ACTION_HEADER = 'ACTION';

export const ACTION_GET_FINAL_READS = 'GET FINAL READS';

export const GRID_ACTION_CSS_CLASS = 'grid-action';

export const CHECKBOX = 'checkbox';

export const DATE_FILTER_CRITERIA = {
  TODAY: 'Today',
  YESTERDAY: 'Yesterday',
  LAST_WEEK: 'Last Week',
  LAST_MONTH: 'Last Month',
  CURRENT_MONTH: 'Current Month',
  LAST_30_DAYS: 'Last 30 Days',
  LAST_3_MONTHS: 'Last 3 Months',
  CURRENT_YEAR: 'Current Year',
  PREVIOUS_YEAR: 'Last Year',
  CUSTOM: 'Custom',
};

export const INTERVAL = [
  {
    name: '1 min',
    value: '1 min',
  },
  {
    name: '5 min',
    value: '5 min',
  },
  {
    name: '10 min',
    value: '10 min',
  },
  {
    name: '15 min',
    value: '15 min',
  },
  {
    name: '30 min',
    value: '30 min',
  },
  {
    name: '1 hour',
    value: '1 hour',
  },
  {
    name: '6 hour',
    value: '6 hour',
  },
  {
    name: '1 day',
    value: '1 day',
  },
  {
    name: '1 month',
    value: '1 month',
  },
  {
    name: '1 year',
    value: '1 year',
  },
];

export const SHOW_INTERVAL = 'Interval';

export const SHOW_CUMULATIVE = 'Cumulative';

export const barGraphColor = () => {
  const localUserSetting = localStorage.getItem(USER_SETTING);
  let returnColor = '#3491e3';
  if (localUserSetting) {
    const userSetting = JSON.parse(localUserSetting);
    if (userSetting && userSetting.chartColor) returnColor = userSetting.chartColor;
  }
  return returnColor;
};

export const meterTypeIcons = [
  {
    type: 'water',
    icon: 'mdi mdi-water-pump',
  },
  {
    type: 'gas',
    icon: 'mdi mdi-gas-station',
  },
  {
    type: 'electricity',
    icon: 'mdi mdi-power-plug',
  },
  {
    type: 'thermal',
    icon: 'icon-energy',
  },
];

export const DATE_TIME_FORMAT = {
  DATE: 'DD-MM-YYYY',
  DATE_TIME: 'DD-MM-YYYY HH:mm',
  DATE_PRECISE_TIME: 'DD-MM-YYYY HH:mm:ss',
  REQUEST_DATE: 'YYYY-MM-DD',
  DATE_TIME_STANDARD: 'YYYY-MM-DD HH:mm:ss',
  REQUEST_DATE_TIME: 'YYYY-MM-DD HH:mm',
  REQUEST_DATE_PRECISE_TIME: 'YYYY-MM-DD HH:mm:ss.SSS',
  DATE_TIME_RAW: 'YYYY-MM-DDTHH:mm:ssZ',
  DATE_TIME_DATEPICKER: 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ'
};

export const Australia = 'Australia/';

export const TIMEZONES = [
  {
    value: `${Australia}Adelaide`,
    // text: "(UTC+09:30) - Adelaide"
  },
  {
    value: `${Australia}Brisbane`,
    // text: "(UTC+10:00) - Brisbane"
  },
  {
    value: `${Australia}Darwin`,
    // text: "(UTC+09:30) - Darwin"
  },
  {
    value: `${Australia}Hobart`,
    // text: "(UTC+10:00) - Hobart"
  },
  {
    value: `${Australia}Melbourne`,
    // text: "(UTC+09:30) - Melbourne"
  },
  {
    value: `${Australia}Perth`,
    // text: "(UTC+08:00) - Perth"
  },
  {
    value: `${Australia}Sydney`,
    // text: "(UTC+10:00) - Sydney"
  },
] as ITimezone[];

export const TABS_CONFIGURATION = {
  DASHBOARD: 'Dashboard',
  DV_CHANNEL: 'DV Channel',
  FINAL_READING: 'FinalReading',
  VIEW_READING: 'ViewReading',
  SYSTEM_CONFIGURATION: 'SystemConfiguration',
  AGGREGATION: 'Aggregation',
  TOU: 'TOU',
  NMI_SUFFIX: 'nmi_suffix',
  UOM_CONFIG: 'UOM Config',
  DEVICE_STATUS: 'Device Status',
  NEM12_REPORT: 'NEM12 Report',
  MDN_REPORT: 'MDN Report',
  EMAIL_SETTINGS: 'Email Settings',
  METER_SCHEDULE: 'Meter Schedule',
  HOLIDAY_LIST: 'Holiday List',
  REPORT_RETENTION: 'Report Retention',
  CUSTOM_PROPERTY: 'Custom Property',
};

export const LOCAL_STORAGE_KEYS = {
  SYSTEM_CONFIG: 'SYSTEM_CONFIG',
};

export const SYSTEM_CONFIG_KEYS = {
  DECIMAL_VALUE: 'DECIMAL_VALUE',
  NUMBER_OF_METERS_TO_FETCH_DATA_FOR_REPORT_EXECUTION: 'NUMBER_OF_METERS_TO_FETCH_DATA_FOR_REPORT_EXECUTION',
  MAX_DEGREE_OF_PARALLELISM: 'MAX_DEGREE_OF_PARALLELISM',
  AI_SIGN_IN: 'AI_SIGN_IN',
  AI_CONTACT: 'AI_CONTACT',
  HELPDESK_EMAIL: 'HELPDESK_EMAIL',
  MAX_RETRY_BLOB_ATTEMPTS: 'MAX_RETRY_BLOB_ATTEMPTS',
  SYSTEM_NOTIFICATION: 'SYSTEM_NOTIFICATION',
  DEFAULT_PROFILE_CONFIGURATION: 'DEFAULT_PROFILE_CONFIGURATION',
  MAX_RETRY_QUEUE_MESSAGE_ATTEMPTS: 'MAX_RETRY_QUEUE_MESSAGE_ATTEMPTS',
  VALIDATE_MDN_XML_FILE: 'VALIDATE_MDN_XML_FILE',
  IS_DATA_SUBSTITUTION: 'IS_DATA_SUBSTITUTION',
  POWER_OUTAGE_REASON_CODE: 'POWER_OUTAGE_REASON_CODE',
  POWER_OUTAGE_STATUS: 'POWER_OUTAGE_STATUS',
  POWER_OUTAGE_NULL_STATUS: 'POWER_OUTAGE_NULL_STATUS',
  MAX_RETRY_CET_METER_DATASYNC: 'MAX_RETRY_CET_METER_DATASYNC',
  EXCLUDE_CET_METER_DATASYNC: 'EXCLUDE_CET_METER_DATASYNC',
  CET_PASSWORD: 'CET_PASSWORD',
  NUMER_OF_DAYS_TO_ALLOW_AE_RESYNC: 'NUMER_OF_DAYS_TO_ALLOW_AE_RESYNC',
  SCHEDULE_TIME_IN_SECONDS_FOR_AE_RESYNC_PROCESS: 'SCHEDULE_TIME_IN_SECONDS_FOR_AE_RESYNC_PROCESS',
  NUMBER_OF_DAYS_FOR_AE_RESYNC_PROCESS: 'NUMBER_OF_DAYS_FOR_AE_RESYNC_PROCESS',
  NUMBER_OF_METERS_TO_FETCH_DATA_FOR_AE_RESYNC_PROCESS: 'NUMBER_OF_METERS_TO_FETCH_DATA_FOR_AE_RESYNC_PROCESS',
  AE_UNVALIDATED_METERS_RESYNC_SCHEDULE_MESSAGE_TIME_MINUTES: 'AE_UNVALIDATED_METERS_RESYNC_SCHEDULE_MESSAGE_TIME_MINUTES',
  NUMBER_OF_METERS_TO_FETCH_DATA_FOR_AE_PROCESS: 'NUMBER_OF_METERS_TO_FETCH_DATA_FOR_AE_PROCESS',
  CET_API_USERNAME: "CET_API_USERNAME",
  CET_API_PASSWORD: "CET_API_PASSWORD",
};

export const AGGREGATION_FUNCTION = {
  AVG: 'AVG',
  LASTVALUE_FIRSTVALUE: 'LastValue – FirstValue',
  MAX: 'MAX',
  MIN: 'MIN',
  SUM: 'SUM',
};

export const WIDGET_TYPE = {
  CHART: 'Chart',
  VALUE_CARD: 'Value Card',
};

export const DATE_DIFF_TYPE = {
  MILLI_SECONDS: 'millisecond',
  SECONDS: 'second',
  HOURS: 'hour',
  MINUTES: 'minute',
  DAY: 'day',
  MONTH: 'month',
  YEAR: 'year',
};

export const LAYOUT_POSITION = {
  DASHBOARD: 'Dashboard',
  ALL_SITES: 'All Sites',
  SINGLE_SITE: 'Single Site',
  SINGLE_PROPERTY: 'Single Property',
  METER_SUMMARY: 'Meter Summary',
};

export const DEVICE_STATUS_OPEN_FROM = {
  SITE: 'site',
  PROPERTY: 'property',
  METER: 'meter',
}

export const AGGREGATION_WIDTH = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

export const AGGREGATION_TYPE = {
  CUMULATIVE: 'Cumulative',
  INSTANTANEOUS: 'Instantaneous',
  INTERVAL: 'Interval',
};

export const AGGREGATION_GROUP_BY = [
  {
    label: 'Date',
    value: 'date',
  },
  {
    label: 'Day of week',
    value: 'day_of_week',
  },
  {
    label: 'Meter group',
    value: 'meter_group',
  },
  {
    label: 'Property name',
    value: 'property_name',
  },
  {
    label: 'Site name',
    value: 'site_name',
  },
];

export const TOKEN_EXPIRY_DIFF = 120;

export const LOCALSTORAGE_KEY = {
  ABILITIES: 'abilities',
  PERMISSION: 'permission',
};

export const SCHEDULE_LINK_LOCATION = {
  DASHBOARD: 'DASHBOARD',
  SITES: 'SITES',
};

export const ADMIN = 'Admin';

export const METER_GROUP = 'MeterGroup';

export const DARK_THEME_COLOR = {
  BG: '#323743',
  CO: '#b2b9bf',
  CONTROL_BG: '#001e3c',
  CONTROL_BORDER_COLOR: '#4d6277',
  OPTION_FOCUS_COLOR: "#0a2744"
};

export const REASON_CODE = '79';

export const REASON_CODE_COLOUR = '#8DAB06';

export const LOGO_TYPE_DB = {
  BIG_LOGO: 'big',
  SMALL_LOGO: 'small',
  DARK_BIG_LOGO: 'darkBig',
  DARK_SMALL_LOGO: 'darkSmall',
};

export const LOGO_TYPE = {
  BIG_LOGO: 'bigLogo',
  SMALL_LOGO: 'smallLogo',
  DARK_BIG_LOGO: 'darkBigLogo',
  DARK_SMALL_LOGO: 'darkSmallLogo',
};

export const TOU_DAYS = [
  'All',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export const MONTH_DATA = [
  {
    name: 'January',
    value: 1,
  },
  {
    name: 'February',
    value: 2,
  },
  {
    name: 'March',
    value: 3,
  },
  {
    name: 'April',
    value: 4,
  },
  {
    name: 'May',
    value: 5,
  },
  {
    name: 'June',
    value: 6,
  },
  {
    name: 'July',
    value: 7,
  },
  {
    name: 'August',
    value: 8,
  },
  {
    name: 'September',
    value: 9,
  },
  {
    name: 'October',
    value: 10,
  },
  {
    name: 'November',
    value: 11,
  },
  {
    name: 'December',
    value: 12,
  },
];

export const TOU_MONTH_DAYS = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
];
export const chartGray = '#8080803c';

export const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const DATE = 'date';

export const DAY_OF_WEEK = 'day_of_week';

export const LORA_APP_KEY = 'LORAAPPKEY';

export const DISABLED = 'disabled';

export const IMPORT_SCHEDULE_STATUS = {
  COMPLETED: 'Completed',
  FAILED: 'Failed',
  STARTED: 'Started',
  PARTIAL_DATA: 'Partial Data',
  PENDING: 'Pending'
};

export const LogsContainerName = {
  NEM12FileImport: 'NEM12FileImportProcessor',
  ScheduleMeterImport: 'ImportMeterFile',
  IOTHubDataProcessor: 'IOTHubDataProcessor',
  IOTHubDataSync: 'iothub-data-sync',
  ENEM12REPORT: 'enem12',
  OPENWeb: 'OpenWebRawFileImportProcessor',
  UnprocessedFiles: 'UnprocessedFiles',
  eNEM12FileProcessor: 'ENEM12FileImportProcessor',
  ErrorFiles: "ErrorFiles",
  MeterReadings: "MeterReadings",
  MeterData: "MeterData",
  FileImportSecureMeterProcessor: 'FileImportSecureMeterProcessor',
  AdvanceEcoProcessor: 'MDMMeterDataSync',
  CETMeterScheduler: 'cet-data-sync',
  CETMeterResync: 'cet-data-resync',
  IOTChirpStack: 'IOTChirpStack'
};

export const LogsContainerLabel = {
  NEM12FileImport: 'NEM12 File Processor',
  ScheduleMeterImport: 'Meter Schedule Import',
  IOTHubDataProcessor: 'IOT Hub Scheduler',
  IOTHubDataSync: 'IOT Data Sync',
  ENEM12REPORT: 'eNEM12 Report',
  OPENWeb: 'OPENWeb',
  SERVICE_BUS: 'Service Bus (Queues)',
  UnprocessedFiles: 'Unprocessed Files',
  eNEM12FileProcessor: 'eNEM12 File Processor',
  DataValidationReport: 'Data Validation Report',
  ErrorFiles: 'Error Files',
  MeterReadings: 'Meter Reading Queue',
  MeterData: 'Meter Data Queue',
  FileImportSecureMeterProcessor: 'Import Secure Meter',
  AdvanceEcoProcessor: 'Advance Eco Processor',
  CETMeterScheduler: 'CET Meter Scheduler',
  CETMeterResync: 'CET Meter Resync',
  IOTChirpStack: 'IOT Chirp Stack'
}

export const SERVICE_BUS = 'SERVICEBUS';

export const ActivityData = [
  {
    value: LogsContainerName.NEM12FileImport,
    label: LogsContainerLabel.NEM12FileImport,
  },
  {
    value: LogsContainerName.ScheduleMeterImport,
    label: LogsContainerLabel.ScheduleMeterImport,
  },
  {
    value: LogsContainerName.IOTHubDataProcessor,
    label: LogsContainerLabel.IOTHubDataProcessor,
  },
  {
    value: LogsContainerName.FileImportSecureMeterProcessor,
    label: LogsContainerLabel.FileImportSecureMeterProcessor,
  },
  {
    value: LogsContainerName.IOTHubDataSync,
    label: LogsContainerLabel.IOTHubDataSync,
  },
  {
    value: SERVICE_BUS,
    label: LogsContainerLabel.SERVICE_BUS,
  },
  {
    value: LogsContainerName.OPENWeb,
    label: LogsContainerLabel.OPENWeb,
  },
  {
    value: LogsContainerName.UnprocessedFiles,
    label: LogsContainerLabel.UnprocessedFiles
  },
  {
    value: LogsContainerName.eNEM12FileProcessor,
    label: LogsContainerLabel.eNEM12FileProcessor
  },
  {
    value: LogsContainerName.ErrorFiles,
    label: LogsContainerLabel.ErrorFiles
  },
  {
    value: LogsContainerName.MeterReadings,
    label: LogsContainerLabel.MeterReadings
  },
  {
    value: LogsContainerName.MeterData,
    label: LogsContainerLabel.MeterData
  },
  {
    value: LogsContainerName.AdvanceEcoProcessor,
    label: LogsContainerLabel.AdvanceEcoProcessor
  },
  {
    value: LogsContainerName.CETMeterScheduler,
    label: LogsContainerLabel.CETMeterScheduler
  },
  {
    value: LogsContainerName.CETMeterResync,
    label: LogsContainerLabel.CETMeterResync
  },
  {
    value: LogsContainerName.IOTChirpStack,
    label: LogsContainerLabel.IOTChirpStack
  }
];

export const CONTAINER_UNPROCESSED_FILES = [
  {
    value: "nem12-raw-files",
    label: "NEM12",
  },
  {
    value: "enem12-raw-files",
    label: "eNEM12",
  },
  {
    value: "openweb-raw-files",
    label: "OPENWeb",
  }
]

export const CONTAINER_ERROR_FILES = [
  {
    value: "nem12-error-files",
    label: "NEM12",
  },
  {
    value: "enem12-error-files",
    label: "eNEM12",
  },
  {
    value: "openweb-error-files",
    label: "OPENWeb",
  }
]

export const LOGSSTATUS = [
  "Started", "Partial Data", "Failed", "Completed", "Pending"
]

export const VIEWLOGSSTATUS = [
  "Started", "Failed", "Completed", "Partial Data"
]

export const EXECUTION_TYPE = [
  "Adhoc", "Scheduler"
]

export const EXECUTION_VALIDATION_TYPE = [
  "Adhoc", "Scheduled"
]

export const EXECUTION_SPECIAL_TYPE = [
  "Adhoc", "Resend", "Scheduler"
]

export const OPERATORS = [
  {
    value: '+',
  },
  {
    value: '-',
  },
  {
    value: '*',
  },
  {
    value: '/',
  },
  {
    value: '^',
  },
  {
    value: '|/',
  },
];

export const FUNCTIONS = [
  {
    value: 'Abs',
  },
  {
    value: 'Avg',
  },
  {
    value: 'Cbrt-(Cube Root)',
  },
  {
    value: 'Count',
  },
  {
    value: 'Max',
  },
  {
    value: 'Min',
  },
  {
    value: 'Round',
  },
  {
    value: 'Sqrt',
  },
  {
    value: 'Sum',
  },
];

export const STEP_RADIO_SELECTION = {
  METER_NAME: 'Meter Name',
  PREVIOUS_STEP: 'Previous Step Output',
  CONSTANT: 'Constant',
};

export const CALCULATION_TYPE = {
  MC: 'MC',
  PO: 'PO',
  CV: 'CV',
};

export const FILETYPENEM12 = ['NEM12', 'e-NEM12'];

export const REPORT_PERIOD = [
  {
    value: 'hour',
    label: 'Hour',
  },
  {
    value: 'day',
    label: 'Day',
  },
  {
    value: 'week',
    label: 'Week',
  },
  {
    value: 'month',
    label: 'Month',
  },
];

export const VALUE_TYPE = {
  ACTUAL: 'Actual',
  USAGE: 'Delta',
};
export const VALUE_LABEL = {
  ACTUAL: 'Actual',
  USAGE: 'Usage',
}

export const REPORT_COLUMNS = {

  NAME: {
    HEADER: 'Name',
    ACCESSOR: 'name'
  },
  TIMEZONE: {
    HEADER: 'Timezone',
    ACCESSOR: 'report_timezone'
  },
  CRON_EXPRESSION: {
    HEADER: 'Cron Expression',
    ACCESSOR: 'execution_cron'
  },
  REPORT_PERIOD: {
    HEADER: 'Report Period',
    ACCESSOR: 'report_period'
  },
  CUSTOMER: {
    HEADER: 'Report Sharing',
    ACCESSOR: 'customer'
  },
  ACTION: {
    HEADER: 'Action',
    ACCESSOR: 'id'
  },
}

export const HEADER_CLASS_NAME = 'react-table-grid-header'

export const METER_COLUMNS = {

  METER_NAME: {
    HEADER: 'Meter Name',
    ACCESSOR: 'meter_id'
  },
  METER_PROPERTY_NUMBER: {
    HEADER: 'Meter Property Number',
    ACCESSOR: 'meter_property_number'
  },
  METER_SERIAL_NUMBER: {
    HEADER: 'Meter Serial Number',
    ACCESSOR: 'meter_serial_number'
  },
  NMI: {
    HEADER: 'NMI',
    ACCESSOR: 'nmi'
  },
  PROPERTY_NAME: {
    HEADER: 'Property Name',
    ACCESSOR: 'name'
  },
  CUSTOMER_NAME: {
    HEADER: 'Customer Name',
    ACCESSOR: 'customer_name'
  }
}

export const METER_GROUP_COLUMNS = {

  METER_GROUP: {
    HEADER: 'Meter Group',
    ACCESSOR: 'group_name'
  },
  DESCRIPTION: {
    HEADER: 'Description',
    ACCESSOR: 'group_description'
  },
  NO_OF_METERS: {
    HEADER: 'No Of Meters',
    ACCESSOR: 'no_of_meters'
  },
  ACTION: {
    HEADER: 'Action',
    ACCESSOR: 'id'
  },
}


export const METER_SERIAL = [
  {
    value: 'METER_SERIAL_NUMBER',
    label: 'Meter Serial Number',
  },
  {
    value: 'METER_PROPERTY_NUMBER',
    label: 'Meter Property Number',
  },
  {
    value: 'NMI',
    label: 'NMI',
  },
  {
    value: 'CUSTOM_PROPERTY',
    label: 'Custom Property',
  },
];

export const MISSING_DATA_HANDLING_NEM12 = [
  {
    value: 'USE_SUBSTITUTION',
    label: 'Use substitution',
  },
  {
    value: 'LOCF',
    label: 'Interpolate (LOCF)',
  },
  {
    value: 'LINEAR',
    label: 'Interpolate - Linear',
  },
  {
    value: 'INSERT_AS_NULL',
    label: 'Insert as Null',
  },
];

export const MISSING_DATA_HANDLING_ENEM12 = [
  {
    value: 'NONE',
    label: 'None',
  },
  {
    value: 'LOCF',
    label: 'Interpolate (LOCF)',
  },
  {
    value: 'LINEAR',
    label: 'Interpolate - Linear',
  },
  {
    value: 'EXCLUDE_NULL_DATA',
    label: 'Exclude null Data',
  },
];

export const NEGATIVE_DATA_HANDLING = [
  {
    value: 'NONE',
    label: 'None',
  },
  {
    value: 'ZERO',
    label: 'Convert to Zero',
  },
];

export const CHANGE_METER_SETTING = [
  {
    value: 'NONE',
    label: 'Change All Details',
  },
  {
    value: 'CHANGE_SETTINGS',
    label: 'Change Settings',
  },
  {
    value: 'CHANGE_METER',
    label: 'Change Meter',
  },
];

export const NONE = 'NONE';
export const CHANGE_METER = 'CHANGE_METER';
export const CHANGE_SETTINGS = 'CHANGE_SETTINGS';
export const IOT_SENSOR = "IOT Sensor";

export const IOT_CALCULATION_VALUES = [
  {
    value: 'difference',
    label: 'Difference',
  },
  {
    value: 'offset',
    label: 'Offset',
  }
];

export const MISSING_DATA = [
  "Days", "Period"
]

export const CUMULATIVE_VALUE_ERRORS = [
  'Always same value',
  'Larger than previous'
]

export const OFFLINE_ALERTS = [
  'Hour', 'Day'
]


export const RECORD_STATUS_CSS_CLASS = {
  ACTIVE: "record-as-active",
  INACTIVE: "record-as-inactive"
}

export const GET_NOTIFICATION_TYPES = {
  BOTH: 'both',
  ERROR: 'error',
  SUCCESS: 'success'
}

export const GET_NOTIFICATION = [
  {
    value: GET_NOTIFICATION_TYPES.BOTH,
    label: 'Both',
  },
  {
    value: GET_NOTIFICATION_TYPES.ERROR,
    label: 'Error',
  },
  {
    value: GET_NOTIFICATION_TYPES.SUCCESS,
    label: 'Success',
  }
];

export const GET_NOTIFICATION_TYPES_FOR_METER_ALARM_EVENT = {
  BOTH: 'both',
  ON: 'on',
  OFF: 'off'
}

export const GET_NOTIFICATION_FOR_METER_ALARM_EVENT = [
  {
    value: GET_NOTIFICATION_TYPES_FOR_METER_ALARM_EVENT.BOTH,
    label: 'Both',
  },
  {
    value: GET_NOTIFICATION_TYPES_FOR_METER_ALARM_EVENT.ON,
    label: 'On',
  },
  {
    value: GET_NOTIFICATION_TYPES_FOR_METER_ALARM_EVENT.OFF,
    label: 'Off',
  }
];

export const METER_SCHEDULE_TABS = {
  METRIX_METERS: 'MatrixMeters',
  METER_SCHEDULE: 'ScheduleMeters',
}


export const REPORT_TYPES = [
  {
    value: 'Enem12ReportExecutionLogs',
    label: 'eNEM12',
  },
  {
    value: 'NEM12ReportExecutionLogs',
    label: 'NEM12',
  },
  {
    value: 'NEM12QualityFLagReport',
    label: 'NEM12 Quality Flag',
  },
  {
    value: 'MeterEventReport',
    label: 'Meter Event',
  },
  {
    value: 'MDNReportExecutionLogs',
    label: 'MDN',
  },
]

export const MSATS_OPTIONS = ["Use Report execution time", "Custom"];


export const MUTED_COLOR = '#aab0b5';

export const SYSTEM_NOTIFICATION = {
  REPORT: 'Report',
  FILE: 'File',
}

export const NEM12ReportConfigurations = ["Stream Identifier", "Data Substitution Type", "Register ID", "Transaction Code", "NEM12 Status Mapping", "NMI Configuration"];

export const EMAIL_SETTINGS = ["SMTP", "API_KEY"];

export const TABS_IMPORT = {
  IMPORT_METERS: 'ImportMeters',
  ADVANCED_ECO: 'AdvancedEco',
};

export const SEARCH_BY = [
  {
    value: 'Station',
    label: 'Station Name',
  },
  {
    value: 'Device',
    label: 'Device Name',
  }
]

export const SEARCH_BY_OPERATION = [
  {
    value: 'Update',
    label: 'Update',
  },
  {
    value: 'Delete',
    label: 'Delete',
  }
]

export const QUERY_TYPE = [
  {
    value: 'StartsWith',
    label: 'Starts with',
  },
  {
    value: 'ExactMatch',
    label: 'Exact match ',
  }
]

export const METRIX_METERS_CONFIGURATION = [
  {
    value: '',
    label: 'All',
  },
  {
    value: 'include',
    label: 'Include',
  },
  {
    value: 'exclude',
    label: 'Exclude',
  }
]

export const WARNING_MESSAGE = 'Cron Scheduler minute component should be only set for 0 minutes when using Hour report period';

export const NUL12_NULL_DATA_HANDLING_OPTIONS = ["Insert as Null"];
export const DURATION_PREVIEW = [
  {
    value: 'daily',
    label: 'Daily',
  },
  {
    value: 'hourly',
    label: 'Hourly',
  }
]

export const PERMISSION_TYPES = {
  VIEW: 'View',
  ADD: 'Add',
  EDIT: 'Edit',
  DELETE: 'Delete'
}

export const CHART_TYPES = ['Line', 'Bar'];

export const REQUEST_TYPE = {
  SUPPORT_QUESTION: 'Support question',
  REPORT_ISSUE: 'Report an issue',
  FEEDBACK: 'Feedback',
  NEW_USER: 'New user'
}

export const TYPE_OF_REQUEST = [
  {
    value: REQUEST_TYPE.SUPPORT_QUESTION,
    label: REQUEST_TYPE.SUPPORT_QUESTION,
  },
  {
    value: REQUEST_TYPE.REPORT_ISSUE,
    label: REQUEST_TYPE.REPORT_ISSUE,
  },
  {
    value: REQUEST_TYPE.FEEDBACK,
    label: REQUEST_TYPE.FEEDBACK,
  },
  {
    value: REQUEST_TYPE.NEW_USER,
    label: REQUEST_TYPE.NEW_USER,
  }
];

export const VIEW_PERMISSION = {
  YES: 1,
  NO: 0
}

export const MAX_FILE_SIZE = 30;

export const chartToolTipText = `
<ul class='ps-3 m-2' style='font-size:14px'>
    <li>Hold down alt key and scroll to zoom.</li>
    <li>
        Hold ctrl key and click in chart to enable panning.
    </li>
    <li>
        <span class='fw-bold'>Note: </span>
        The pan function will only be available when the chart
        is zoomed in.
    </li>
    <li>
      Click to reset Chart
    </li>
<ul>
`;

export const RichTextEditorColors = {
  Light: '#ffffffe6',
  Dark: '#001e3c'
}

export const IOT_PAYLOAD_DATATYPE = {
  PulseToLoRAWAN: 'PulseToLoRAWAN',
  EcoToLoRAWAN: 'EcoToLoRAWAN',
  LoRaWANSensor: 'LoRaWANSensor',
  NativeMBus: 'NativeMBus',
  PulseToMBus: 'PulseToMBus',
  BACNet: 'BACNet',
  Modbus: 'Modbus'
}

export const IOT_PAYLOAD_DATATYPES = [
  {
    text: 'PulseToLoRAWAN',
    value: 'PulseToLoRAWAN'
  },
  {
    text: 'GWF-EcotoLorawan',
    value: 'EcoToLoRAWAN'
  },
  {
    text: 'LoRaWANSensor',
    value: 'LoRaWANSensor'
  },
  {
    text: 'NativeMBus',
    value: 'NativeMBus'
  },
  {
    text: 'PulseToMBus',
    value: 'PulseToMBus'
  },
  {
    text: 'BACNet',
    value: 'BACNet'
  },
  {
    text: 'Modbus',
    value: 'Modbus'
  }
]

export const METER_ALARM_EVENT_TYPES = [
  {
    value: 'Site',
    label: 'Site'
  },
  {
    value: 'Property',
    label: 'Property'
  },
  {
    value: 'Meter',
    label: 'Meter'
  },
]

export const CRON_EXPRESSION_GENERATOR = 'https://www.freeformatter.com/cron-expression-generator-quartz.html';

export const CUSTOM_PROPERTY = 'CUSTOM_PROPERTY'

export const EVENT_SOURCE_OPTIONS = [
  {
    value: 'IOTPayload',
    label: 'IOT Payload'
  },
  {
    value: 'MeterEvent',
    label: 'Meter Event'
  },
]

export const ACTUALITY_DURATION = {
  MINUTES: 60,
  QUARTER_HOURS: 156,
  DAYS: 200,
  WEEKS: 253
};

export const EMAIL_REGEX = /^[a-zA-Z\d%+-][\w.'%+-]*(?:'[^\s]*)?@[\w.-]+\.[a-zA-Z]{2,}$/;

export const EMAIL_DOMAIN_REGEX = /^@[\w.-]+\.[a-zA-Z]{2,}$/;

export const CANCELLED_REQUEST_STATUS = 499;

export const CANCELLED_REQUEST_MESSAGE = "CANCELLED";

export const PHONENUMBER_REGEX = /^(?:\+?\d+|\(\d+\)|\d+)(?:[ -](?:\+?\d+|\(\d+\)|\d+))*$/;

export const REQUIRED_FIELDS = ['Date', 'Holiday Name'];

export const COLUMN_TO_REMOVE = 'ID';

export const OPTIONAL_FIELDS = ['Information', 'More Information', 'Jurisdiction', 'ID'];

export const FALLBACK_INTERVAL_LENGTH_REGEX = /^[0-9]+?$/;

export const GAS_METER_UNIT = [
  {
    value: 'I',
    label: 'Imperial',
  },
  {
    value: 'M',
    label: 'Metric',
  },
];
export const MISSING_DATA_HANDLING_MDN = [
  {
    value: 'LOCF',
    label: 'LOCF',
  },
  {
    value: 'Exclude Null',
    label: 'Exclude null Data',
  },
];
export const REASON_FOR_READ = [
  {
    value: 'SRF',
    label: 'Special Final Read',
  },
  {
    value: 'SRR',
    label: 'Special Reference Read',
  },
  {
    value: 'SRA',
    label: 'Special Account Investigation',
  },
  {
    value: 'SRD',
    label: 'Special Disconnection',
  },
  {
    value: 'SRT',
    label: 'Special Transfer Read',
  },
  {
    value: 'SCH',
    label: 'Schedule Cycle Read',
  },
  {
    value: 'INI',
    label: 'Meter Installation Read',
  },
  {
    value: 'REM',
    label: 'Meter Remove',
  },
  {
    value: 'OSO',
    label: 'Other Service Order',
  },
  {
    value: 'MDV',
    label: 'Meter Data Verify (residual)',
  },
]

export const REPORT_RETENTION_LABEL = {
  NEM12_REPORT: 'NEM12 Report',
  MDN_REPORT: 'MDN Report',
  ENEM12_REPORT: 'eNEM12 Report',
  NEM12_QUALITY_FLAG_REPORT: 'NEM12 Quality Flag Report',
  METER_EVENT_REPORT: 'Meter Event Report',
  DATA_VALIDATION_REPORT: 'Data Validation Report'
}

export const TYPE_OF_READ = [
  {
    value: 'A',
    label: 'Actual',
  },
  {
    value: 'E',
    label: 'Estimated',
  },
  {
    value: 'S',
    label: 'Substituted',
  },
  {
    value: 'C',
    label: 'Customer Own Read',
  },
]

export const ENABLE_DATA_SUBSTITUTION_TYPE = ["Type 14", "Type 15", "Type 17", "Type 19"];

export const REPORT_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
}

export const REPORT_STATUS_LABEL = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
}

export const METER_SCHEDULE_DROPPABLEID = {
  availableItems: 'available_droppable',
  adminOnlyColumns: 'admin_droppable',
  selectedColumns: 'selected_droppable',
}

export const CUSTOM_PROPERTY_DROPPABLEID = {
  availableCustomProperty: 'available_droppable',
  adminOnlyCustomProperty: 'admin_droppable',
  selectedCustomProperty: 'selected_droppable',
}


export const TIME_CONSTRAINTS = {
  hours: { min: 0, max: 0, step: 1 },
  minutes: { min: 0, max: 0, step: 1 },
}

export const reportTypes = [
  'NEM12Report',
  'MDNReport',
  'NEM12QualityFlagReport',
  'ENEM12Report',
  'MeterEventReport',
  'DataValidationReport',
];


export const JURISDICTION = [
  {
    label: 'act',
    value: 'act',
  },
  {
    label: 'nt',
    value: 'nt',
  },
  {
    label: 'qld',
    value: 'qld',
  },
  {
    label: 'sa',
    value: 'sa',
  },
  {
    label: 'vic',
    value: 'vic',
  },
  {
    label: 'tas',
    value: 'tas',
  },
  {
    label: 'wa',
    value: 'wa',
  },
]


export const CUSTOM_PROPERTY_FOR_CARD = {
  METER_MAKE: 'Manufacturer',
  METER_MODEL: 'MeterModel',
  METER_LOCATION: 'MeterLocation',
  LIFE_SUPPORT: 'LifeSupport',
}

export const METER_TYPES_FOR_CARD = {
  Air_Conditioning: 'air-conditioning',
  COLD_WATER: 'cold water',
  ELECTRICITY: "electricity",
  GAS: "gas",
  HOT_WATER: "hot water",
  IOT_SENSOR: "iot sensor",
  RECYCLED_WATER: "recycled water"
}

export const POSITION = { Prefix: 'Prefix', Suffix: 'Suffix', Mid: 'Mid' }

export const POSITION_BY = [
  {
    value: 'Prefix',
    label: 'Prefix',
  },
  {
    value: 'Suffix',
    label: 'Suffix',
  },
  {
    value: 'Mid',
    label: 'Mid',
  }
]

export const REPORT_TYPES_FOR_METER_DATA_CORRECTION = [
  {
    value: 'Enem12ReportExecutionLogs',
    label: 'eNEM12',
  },
  {
    value: 'NEM12ReportExecutionLogs',
    label: 'NEM12',
  },
  {
    value: 'MDNReportExecutionLogs',
    label: 'MDN',
  },
]

export const SEARCH_BY_YES_NO = [
  {
    value: 'No',
    label: 'No',
  },
  {
    value: 'Yes',
    label: 'Yes',
  }
]

export const INTERPOLATION = [
  {
    value: 'LOCF',
    label: 'Interpolate (LOCF)',
  },
  {
    value: 'LINEAR',
    label: 'Interpolate - Linear',
  }
]


export const DATA_VALIDATION_RULE_NAME = {
  OFFLINE_METER: 'Offline meter',
  MISSING_DATA: 'Missing data',
  ZERO_USAGE: 'Zero usage',
  SPIKE:'Spike',
  CUMULATIVE_VALUE_ERRORS:'Cumulative value errors'
}

export const REAL_TIME_DATA_VIEW_DEVICE_TYPE = {
  CET: 'CET',
  EDMI: 'EDMI',
}

export const ADVANCE_ECO_VALIDATE_SUMMARY = {
  ADVANCED_ECO_VALIDATION_SUMMARY: 'Advanced Eco Validation Summary',
  SITE_SUMMARY: 'Sites',
  METER_SUMMARY: 'Meters',
  METER_DETAIL: 'Detail'
}

export const MISSING_ADVANCE_DATA_IN_METRIX = "Unknown";

export const DATA_VALIDATION_REPORT_STATUS = [
  "Completed", "Failed"
]

export const SUCCESS_TYPE=[
  {
    value: 'success',
    label: 'Success',
  },
  {
    value: 'failure',
    label: 'Failure',
  }
]
